@use "@material/card";
@use "@material/slider/styles";
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400&display=swap');

@include card.core-styles;


* {
  box-sizing: border-box;
  z-index: 1;
}

#startConfiguration{
  display: flex !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  align-items: center;
  justify-content: center;
  background: #FF9F37 !important;
  border-radius: 0;
  padding:       20px 45px;
  color:         #ffffff;
  font:          normal bold 18px/1 "Source Sans Pro", sans-serif;
  text-align:    center;
}

#konfiguratorBackground{
  background-image: url("https://www.woodchecker.com/wp-content/uploads/2022/12/konfigurator-blurred.jpg");
  min-height: 860px;
  min-width: 100%;
  display: flex;
  background-size: contain;
  background-repeat: no-repeat;
}

#startConfiguration:hover{
  transition: 1s;
  background-color: rgb(121, 121, 121);
}

.yarl__button{
  color:white !important;
}

.yarl__toolbar {
  top: 10% !important;
  right: 10% !important;
}

.yarl__navigation_next {
  right: 10% !important;
}

.yarl__navigation_prev {
  left: 10% !important;
}


.photo-gallery{
  z-index: 1 !important;
  max-height: 700px;
  overflow-y: auto;
}

.yarl__container{
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.yarl__icon {
  height: 50px !important;
  width: 50px !important;
}

.yarl__portal {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.react-photo-album{
  flex-flow: column nowrap !important;
}

.react-photo-album--column{
  margin-bottom: 10px;
}

/*
 *  STYLE 7
 */

 #style-7::-webkit-scrollbar-track
 {
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
   background-color: #F5F5F5;
   border-radius: 10px;
 }
 
 #style-7::-webkit-scrollbar
 {
   width: 2px;
   background-color: #F5F5F5;
 }
 
 #style-7::-webkit-scrollbar-thumb
 {
   border-radius: 2px;
   background-color: #415265;
 }


.elementor-location-header {
z-index: 2 !important;
}

.css-1nvf7g0 {
margin-left: 0px !important;
}

.css-18m8r0v {
margin-right: -2px !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase{
z-index: 1 !important;
}

.infoText{
  line-height: 120%;
  font-size: 17px !important;
  font-family: "Source Sans Pro", sans-serif !important;
}

html, body, #root {
display: flexbox;
max-width: 100%;
max-height: 100%;
margin: 0;
padding: 0;
scroll-behavior: smooth;
--swiper-theme-color: #415265 !important;
font-family: 'Source Sans Pro', sans-serif !important;
}

html, body {
width: 100%;
background-color: #f1f4f8;
position: absolute;
}

.css-ahj2mt-MuiTypography-root{
font-family: 'Source Sans Pro', sans-serif !important;
}


.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
  color: #415265 !important;
}

.css-eg0mwd-MuiSlider-thumb:hover{
  box-shadow: 0px 0px 0px 8px rgb(0 0 0 / 20%) !important;
}

.css-1hy9t21 {
  height: 40px !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #415265 !important;
}

.css-1tqv6h6 {
  margin-bottom: 0 !important;
}

.mdc-card{
  height: 100%;
  min-width: 340px;
  padding: 20px;
  z-index: 1 !important;
  //background-color: #F2F2F2 !important;
  box-shadow: none !important;
  align-items: center !important;
  margin-top: 0 !important;
}

.mdc-card-dekor{
  width: 100%;
  z-index: 1 !important;
  //background-color: #F2F2F2 !important;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  scroll-behavior: smooth;
  align-items: center !important;
}

.ui-wrapper{
  z-index: 1 !important;
  margin-left: auto;
  margin-right: auto;
}

.css-fvipm8{
  border-radius: 0 !important;
  text-align: center !important;
}

.css-1bn53lx{
  border-radius: 0 !important;
  text-align: center !important;
}

.dekor-wrapper{
  z-index: 1 !important;
  scroll-behavior: smooth;
  width: 100%;

}

.content input{
  text-align: center !important;
  height: 64px;
}

.css-1v4ccyo{
  border-radius: 0 !important;
}

#board_type{
  display: flex;
  justify-content: center;
  align-items: center;
}

.css-1o6z5ng{
  margin: 0px !important;
}

.css-1tqv6h6 {
  margin-bottom: 0 !important;
}

.inStore{
  color: #7E8E65;
}

.deliveryTime{
  color: #473729;
  font-size: .8rem !important;
}

.MuiBox-root{
  margin-top: 15px;
  z-index: 1 !important;
}

.thumbnail-text{
  margin-top: 7px !important;
}

.slider{
  color: #415265 !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  flex-shrink: 0;
  width: 250px !important;
  height: 190px !important;
  position: relative;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  height: 150px !important;
  max-width: 250px!important;
  object-fit: cover;
  scroll-behavior: smooth;
  width: 250px!important;
}

.css-1hy9t21 {
  border: 2px solid #415265 !important;
  border-radius: 50% !important;
  border: none !important;
}

.css-eg0mwd-MuiSlider-thumb{
  border-radius: 0px !important;
}

.wpcf7-number{
  margin-bottom: 0px !important;
}

.css-1bn53lx {
  text-align: center !important;
}

.css-1uvydh2 {
  text-align: center !important;
}

#single_add_to_cart_button{
  background: #FF9F37 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 14px;
  border-radius: 0 !important;
  width: 300px;
  height: 48px;
}


.css-b52kj1 {
  margin: 0 !important;
}




.priceStyle{
  float: right;
  text-align: right;
  font-size: 22px !important;
  margin-top: 5px !important;
}

.taxStyle{
  float: right;
  font-family: 'Source Sans Pro', sans-serif !important;
}

.react-photo-album--column {
  border: 1px solid;
}

.css-1oaoggr {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  margin-top: -8px;
  width: calc(100% + -150px) !important;
  /* margin-left: -8px; */
  justify-content: space-evenly;
  margin-bottom: 10px;
  font-family: 'Source Sans Pro', sans-serif !important;
}

canvas{
  max-height: 500px !important;
}

.css-qiwgdb{
  color: #473729 !important;
  font-family: 'Source Sans Pro', sans-serif !important;
}

.css-9l3uo3 {
  line-height: 120% !important;
  color: #473729 !important;
  font-family: 'Source Sans Pro', sans-serif !important;
}

.css-348fk2{
  color: #473729 !important;
  font-family: 'Source Sans Pro', sans-serif !important;
}

.swiper{
    width: 100% !important;
}

@media only screen and (max-width: 1100px) {
  
  .grid-item-photogallery{
    display: none;
  }


  .css-8nvpxx {
    box-sizing: border-box;
    display: flex;
    flex-flow: column wrap !important;
    margin-top: -1.6px;
    width: calc(100% + 1.6px);
    margin-left: -1.6px;
    justify-content: space-evenly;
    align-items: center;
}
  .dekorgallery{
    max-width: 100%;
  }

  .css-4xkoi8 {
    max-width: 100% !important;
}

.css-9l3uo3 {
  text-align: left;
  line-height: 120% !important;
}

.css-1s50f5r {
  width: 100% !important;
  max-width: 100% !important;
  flex-basis: 100% !important;
}
  .css-1udb513{
    width: 100% !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .model{
    width: 100% !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  
canvas{
  max-height: 500px !important;
}
  
}